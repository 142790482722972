@import "../index.css";

.navbar {
	width: 100%;
	height: 5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--primary-color);
	background-color: var(--secondary-color);
	padding: 0 30px 0 30px;
	box-sizing: border-box;
	position: sticky;
	top: 0;
	z-index: 100;
	overflow: visible;
}
.navbar-logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	color: var(--primary-color);
}
.navbar-logo:hover {
	text-decoration: none;
	color: var(--primary-color);
}
.company-logo {
	height: 2rem;
	width: 2rem;
	border-radius: 5%;
}
.company-name {
	font-size: medium;
	user-select: none;
	text-align: center;
}
.slogan {
	font-size: xx-small;
	user-select: none;
	text-align: center;
}
.navbar-mid-section {
	display: flex;
	gap: 2rem;
	color: var(--primary-color);
	align-items: flex-end;
}
.navbar-toggler {
	box-shadow: none !important;
}
.toggler {
	color: var(--primary-color);
	font-size: 1.6rem;
}
.offcanvas {
	background-color: var(--secondary-color);
	font-size: small;
	width: 70%;
}
.close-btn {
	color: var(--primary-color);
	font-size: large;
	cursor: pointer;
}
.nav-link {
	color: var(--primary-color);
	text-decoration: none;
	cursor: pointer;
	user-select: none;
	margin-left: 20px;
}
.nav-link:hover,
.nav-link:focus {
	color: var(--primary-color);
}
.drop {
	background-color: var(--secondary-color);
	padding-top: 10px;
}
.dropdown-toggle::after {
	display: none;
}
.drop-item {
	border-top: 1px solid #767676;
	height: 35px;
	display: flex;
	align-items: center;
}
.drop:nth-child(1) {
	margin-top: -20px;
}
.drop:last-child {
	margin-bottom: -20px;
	border-bottom: 1px solid #767676;
}
.drop-item:hover {
	background-color: var(--primary-color);
}
.drop-item > .nav-link {
	font-size: small;
}
.drop-item:hover > .nav-link {
	color: var(--secondary-color);
}
.navbar-right-section {
	display: flex;
	gap: 2rem;
	align-items: flex-start;
}
.cart-count {
	position: absolute;
	width: 1.2rem;
	height: 1.2rem;
	display: inline;
	background-color: white;
	color: red;
	border-radius: 50%;
	font-size: 0.8rem;
	font-weight: 800;
	margin-left: -10px;
	margin-top: 5px;
	z-index: 2;
	text-align: center;
}

.search,
.login,
.checkout,
.home {
	font-size: 1.5rem;
	color: var(--primary-color);
	text-decoration: none;
}
.search:hover,
.login:hover,
.checkout:hover,
.home:hover {
	color: var(--primary-color);
}
.home {
	display: none;
}

@media (max-width: 768px) {
	.navbar-mid-section {
		order: 1;
	}
	.cart-count {
		width: 0.9rem;
		height: 0.9rem;
		font-size: 0.6rem;
	}
}

@media (max-width: 410px) {
	.navbar {
		padding-left: 20px;
		padding-right: 20px;
	}
	.company-name {
		font-size: x-small;
	}
	.slogan {
		font-size: xx-small;
	}
	.search,
	.login,
	.checkout {
		font-size: 1.3rem;
	}
	.navbar-right-section {
		margin-left: -20px;
		gap: 1.5rem;
	}
}

@media (max-width: 576px) {
	.navbar-logo {
		display: none;
	}
	.navbar {
		justify-content: space-between;
	}
	.home {
		align-self: flex-start;
		display: block;
		font-size: 1.4rem;
		margin-left: -20%;
		margin-right: 15%;
	}
}
