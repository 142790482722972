@import "./index.css";

body {
	margin: 0;
	box-sizing: border-box;
	width: 100vw;
	overflow-x: hidden;
}

.success-notification {
	color: #07bc0c !important;
	font-size: small !important;
}

.error-notification {
	color: #e74c3c !important;
	font-size: small !important;
}

.info-notification {
	color: #3498db !important;
	--toastify-spinner-color: #3498db !important;
	font-size: small !important;
}

.add-success-notification {
	color: white !important;
	background-color: #418914 !important;
	--toastify-icon-color-success: white !important;
	--toastify-toast-min-height: 3px;
	font-size: small !important;
}

.remove-success-notification {
	color: white !important;
	background-color: #e74c3c !important;
	--toastify-icon-color-error: white !important;
	--toastify-toast-min-height: 3px;
	font-size: small !important;
}

.Toastify__toast-container {
	z-index: 30000 !important;
}

.ant-menu-item-selected,
.ant-menu-item-active {
	background-color: var(--secondary-color) !important;
	color: var(--primary-color) !important;
	border-radius: 5px !important;
}

.ant-menu-item-selected::after,
.ant-menu-item-active::after {
	border-right: none !important;
}

.customOverlay {
	background: none !important;
}

@media (max-width: 576px) {
	.add-success-notification,
	.remove-success-notification {
		max-width: 70% !important;
		font-size: 0.5rem !important;
		margin-bottom: 0 !important;
		max-height: 40px !important;
		padding-top: 2px !important;
		margin-left: auto;
	}
	.add-success-notification:last-child,
	.remove-success-notification:last-child {
		margin-bottom: 20px !important;
	}
	.Toastify__toast-container {
		max-width: 90% !important;
	}
}
