@import "../index.css";

.slider {
	width: 100%;
	height: 60vh;
	padding: 0 30px 0 30px;
	box-sizing: border-box;
	margin-top: 10px;
	position: relative;
	overflow: none;
}

.slider-div {
	height: 60vh;
}

.slider-image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.slider-info {
	position: absolute;
	right: 10%;
	bottom: 55%;
	transform: translateY(50%);
	z-index: 2;
	color: var(--primary-color);
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 20%;
	font-size: medium;
	line-height: 35px;
	text-align: center;
	align-items: center;
}

.slider-text {
	background-image: linear-gradient(
		-225deg,
		white 0%,
		white 29%,
		var(--primary-color) 67%,
		var(--primary-color) 100%
	);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 10s reverse infinite;
	user-select: none;
}

@keyframes textclip {
	to {
		background-position: 200% center;
	}
}

.shop-btn {
	width: 70%;
	background: transparent;
	border: 1px solid white;
	color: white;
	font-size: medium;
	text-align: center;
	height: 2.5rem;
	cursor: pointer;
	transition: 950ms;
	animation: btnclip 2s reverse infinite;
}

.shop-btn:hover {
	transform: scale(1.08);
	background-color: var(--secondary-color);
	color: var(--primary-color);
	animation: shopbtnclip infinite;
	border: var(--secondary-color);
}

.loading-div {
	position: relative;
	width: 100%;
	height: 80vh;
	background-color: var(--primary-color);
}

.loading {
	font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
	text-transform: uppercase;

	width: 150px;
	text-align: center;
	line-height: 50px;

	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	margin: auto;
	transform: translateY(-50%);
}

.loading span {
	position: relative;
	z-index: 999;
	color: #fff;
}
.loading:before {
	content: "";
	background: var(--secondary-color);
	width: 128px;
	height: 36px;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;

	animation: 2s loadingBefore infinite ease-in-out;
}

@keyframes loadingBefore {
	0% {
		transform: translateX(-14px);
	}
	50% {
		transform: translateX(14px);
	}
	100% {
		transform: translateX(-14px);
	}
}

.loading:after {
	content: "";
	background: #ff3600;
	width: 14px;
	height: 60px;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	opacity: 0.5;

	animation: 2s loadingAfter infinite ease-in-out;
}

@keyframes loadingAfter {
	0% {
		transform: translateX(-50px);
	}
	50% {
		transform: translateX(50px);
	}
	100% {
		transform: translateX(-50px);
	}
}

@media (max-width: 1000px) {
	.slider {
		padding: 0 10px 0 10px;
		height: 50vh;
	}
	.slider-info {
		right: 50%;
		bottom: 70%;
		transform: translate(50%, 70%);
		width: 30%;
		gap: 0.7rem;
		width: 40%;
		line-height: 25px;
	}
	.loading-div {
		height: 50vh;
	}
}

@media (max-width: 650px) {
	.shop-btn {
		font-size: small;
	}
	.slider-info {
		font-size: smaller;
		line-height: 25px;
		width: 60%;
		gap: 0.5rem;
		line-height: 20px;
	}
}
