@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");

* {
	margin: 0;
}
:root {
	--primary-color: #eee8aa;
	--secondary-color: #494f55;
	font-family: "Exo 2", sans-serif;
}
